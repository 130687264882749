import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/vercel/path0/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Playfair_Display\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"Playfair\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./font/SVNGilroy Bold.otf\",\"display\":\"swap\"}],\"variableName\":\"myFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/antd/dist/reset.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/messenger.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/NewZalo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/src/components/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewHeader"] */ "/vercel/path0/src/components/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/vercel/path0/src/contexts/index.tsx");
